import axios from "@/axios.js";
/* eslint-disable */
export default {
  legislationSearch(item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/legislation-search`, item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  GetLegislationSearch(obj) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw?type=legislation` + obj)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  GetLegislation(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  SummarizeLegis(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/legislation-summary/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  OpenSummary(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw/legislation/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  sendQuestion(type, id, item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/case/${type}/${id}/ask`, item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
