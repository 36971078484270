<template>
  <PageLoader v-if="pageLoading" :loading="pageLoading"></PageLoader>

  <div v-else class="container_margin">
    <!-- start of Statement display -->
    <h6><b>Statement</b></h6>
    <div class="card p-2">
      {{ title }}
    </div>
    <!-- end of Statement display -->

    <!-- start of Filter form -->
    <div
      v-if="searched_data.length !== 0"
      class="accordion mt-4"
      id="filtersAccordion"
    >
      <div class="card">
        <div class="card-header" id="filtersAccordionHeading">
          <h6
            class="font-weight-bold mb-0"
            data-toggle="collapse"
            data-target="#filtersAccordionContent"
            aria-expanded="true"
            aria-controls="filtersAccordionContent"
          >
            <input
              class="accordion-checkbox"
              type="checkbox"
              id="toggleFilters"
            />
            <label class="accordion-label" for="toggleFilters">
              Filters - {{ filtered_searched_data.length }} Case(s)
              <span class="material-icons-outlined float-right cursor-pointer accordion-icon">
                expand_more
              </span>
            </label>
          </h6>
        </div>

        <div
          id="filtersAccordionContent"
          class="collapse show"
          aria-describedby="filtersAccordionHeading"
          data-parent="#filtersAccordion"
        >
          <div class="card-body">
            <b class="text-blue font-14">
              {{ "Legislation" }}
            </b>

            <b-form-input
              class="mt-1"
              v-model="search"
              autocorrect="off"
              autocomplete="off"
              placeholder="Legislation"
              @input="filteringContent"
            ></b-form-input>

            <div class="row mt-3">
              <div class="col-6">
                <b class="text-blue font-14">
                  {{ "Legislation Summary Status" }}
                </b>
                <multiselect
                  class="mt-1"
                  id="statesFilter"
                  :maxHeight="250"
                  :optionHeight="20"
                  v-model="chosenFilterStatus"
                  :options="filterStatus"
                  placeholder="Choose Status(es)"
                  @select="filteringContent"
                  @remove="filteringContent"
                  :multiple="true"
                  :close-on-select="false"
                  :searchable="false"
                  selectLabel=""
                  selectedLabel=""
                  track-by="name"
                  label="name"
                  deselectLabel="Click to remove"
                  :limit="2"
                  @open="filterExpand = '65px'"
                  @close="filterExpand = false">
                </multiselect>
              </div>

              <div class="col-6" v-if="filterCountry.length > 1">
                <b class="text-blue font-14">
                  {{ filterCountry.length > 1 ? "Jurisdiction" : "" }}
                </b>
                <multiselect
                  class="mt-1"
                  :maxHeight="150"
                  :optionHeight="20"
                  v-model="chosenFilterCountry"
                  :options="filterCountry"
                  placeholder="Choose Country(s)"
                  :multiple="true"
                  :close-on-select="false"
                  :searchable="false"
                  selectLabel=""
                  selectedLabel=""
                  deselectLabel="Click to remove"
                  :limit="2"
                  @select="filteringContent"
                  @remove="filteringContent"
                  @open="filterExpand = `${(filterCountry.length * 32.95 + 15) > 150 ? 145 : (filterCountry.length * 32.95 + 15)}px`"
                  @close="filterExpand = false">
                </multiselect>
              </div>

              <div
                v-if="filterExpand"
                :style="{ height: filterExpand }"
                class="col-12"
              ></div>

              <div class="col-6 mt-3" v-if="filterCountry.length > 1"></div>
              <div
                :class="[
                  'col-6 align-content-end',
                  filterCountry.length > 1 ? 'mt-3' : '',
                ]"
              >
                <button
                  class="btn btn-primary float-right btn-sm"
                  @click="clearFilter()"
                >
                  {{ "Clear Filters" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end of Filter form -->

    <!-- start of Legislations Search Results -->
    <div class="row mt-4">
      <div class="col">
        <div class="row">
          <div class="col">
            <h6 class="font-weight-bold">{{ "Legislations" }}</h6>
          </div>
        </div>

        <div class="card border shadow-sm p-2 rounded table-card">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" width="10%">{{ "ID" }}</th>
                <th scope="col" width="50%">{{ "Legislation" }}</th>
                <th scope="col" width="20%" class="text-center">
                  {{ "Jurisdiction" }}
                </th>
                <th
                  v-if="permissions.includes('nexlaw.legislation-summary')"
                  scope="col"
                  width="20%"
                  class="text-center"
                >
                  {{ "Action" }}
                </th>
              </tr>
            </thead>
            <tbody>
              <div
                v-if="
                  searched_data.length === 0 ||
                  filtered_searched_data.length === 0
                "
                class="container_margin"
              >
                <div
                  class="row h-100 justify-content-center align-items-center"
                >
                  <div class="col">
                    <div class="mb-3 m-auto" style="width: 200px">
                      <img src="@/assets/noData.png" alt="No Data Image" />
                    </div>

                    <p
                      v-if="searched_data.length === 0"
                      class="text-center text-blue font-weight-bold"
                    >
                      {{ "No Data, Kindly try different country(s)" }}
                    </p>
                    <p
                      v-else-if="filtered_searched_data.length === 0"
                      class="text-center text-blue font-weight-bold"
                    >
                      {{ "No Data, Kindly try different filter(s)" }}
                    </p>
                  </div>
                </div>
              </div>

              <tr v-for="(item, index) in filtered_searched_data" :key="index">
                <td width="10%">{{ index + 1 }}</td>
                <td width="50%">
                  <p v-if="item.name == '' || item.name == null">
                    {{ "-" }}
                  </p>
                  <p v-else v-html="highlightLegislation[index]"></p>
                </td>
                <td width="20%" class="text-center">
                  <p>
                    {{
                      item.jurisdiction == "" || item.jurisdiction == null
                        ? "-"
                        : item.jurisdiction
                    }}
                  </p>
                </td>
                <td
                  v-if="
                    item.completedPerCentage == 'Not enough data' &&
                    permissions.includes('nexlaw.legislation-summary')
                  "
                  width="20%"
                  class="text-center"
                >
                  <button
                    class="btn btn-primary-outlined h-100"
                    @click="SummarizeLegis(item.id)"
                  >
                    {{ "Summarize" }}
                  </button>
                </td>
                <td
                  v-else-if="
                    item.completedPerCentage >= 100 &&
                    permissions.includes('nexlaw.legislation-summary')
                  "
                  width="20%"
                  class="text-center"
                >
                  <button
                    class="btn btn-primary h-100"
                    @click="openLegis(item.id)"
                  >
                    {{ "View Summary" }}
                  </button>
                </td>
                <td v-else width="20%" class="text-center">
                  <button
                    v-if="permissions.includes('nexlaw.legislation-summary')"
                    class="btn btn-warning-outlined h-100"
                    @click="$toast.warning('Still in progress.')"
                  >
                    <span><b>Summarizing:</b> 50% </span>
                    <b-spinner label="Spinning" class="spinner"></b-spinner>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- end of Legislations Search Results -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// import { stopwords } from "./stopwords.js";
import PageLoader from "../../components/PageLoader.vue";
import Auth from "@/store/Auth.js";
import LegislationSearch from "@/store/LegislationSearch.js";
import Multiselect from "vue-multiselect";
export default {
  components: {
    PageLoader,
    Multiselect,
  },

  data() {
    return {
      permissions: localStorage.permissions,
      filterExpand: false,
      pageLoading: true,
      campaign: [],
      searched_data: [],
      filtered_searched_data: [],
      filter: ["All", "Title", "Decision Date", "Court Name"],
      filterCountry: [],
      chosenFilterCountry: [],
      timeout_timer: null,
      timer_count: 0,
      search: "",
      abstract: "",
      title: "",
      searchType: "",
      filterStatus: [
        { id: "summarized", name: "Summarized Case" },
        { id: "summarizing", name: "Summarizing Case" },
        { id: "Not enough data", name: "Unsummarized Case" },
      ],
      chosenFilterStatus: [],
    };
  },

  computed: {
    highlightLegislation() {
      return this.filtered_searched_data.map((data) => {
        if (this.search && this.search !== "") {
          return data.name.replace(
            new RegExp(`(${this.search.toLowerCase()})`, "gi"),
            (match) => `<span style="background-color: orange">${match}</span>`
          );
        }
        return data.name;
      });
    },
  },

  methods: {
    clearFilter() {
      this.filtered_searched_data = this.searched_data;
      this.search = "";
      this.chosenFilterCountry = [];
      this.chosenFilterStatus = [];
    },

    filteringContent() {
      // console.log("filteringContent is triggered");
      // console.log("Status:", this.chosenFilterStatus);

      this.filtered_searched_data = this.searched_data;

      // console.log("Filtered Search Data", this.filtered_searched_data)

      if (this.chosenFilterStatus.length > 0) {
        this.filtered_searched_data = this.filtered_searched_data.filter(
          (element) =>
            this.chosenFilterStatus.includes(element.completedPerCentage)
        );
      }

      Object.values(this.chosenFilterStatus).forEach(item => {
        this.searched_data.forEach(element => {
          if (
            (item.id.includes("summarizing") && !["Not enough data", 100].includes(element.completedPerCentage)) ||
            (item.id.includes("summarized") && element.completedPerCentage >= 100) ||
            (item.id.includes("Not enough data") && element.completedPerCentage == "Not enough data")
          ) {
            this.filtered_searched_data.push(element);
          }
        });
      });

      if (this.search.length > 0) {
        this.filtered_searched_data = this.filtered_searched_data.filter(
          (element) =>
            element.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }

      if (this.chosenFilterCountry.length > 0) {
        this.filtered_searched_data = this.filtered_searched_data.filter(
          (element) => this.chosenFilterCountry.includes(element.jurisdiction)
        );
      }

      // console.log(this.filtered_searched_data);
    },

    openLegis(data) {
      this.$router.push({
        name: "Legislation_Summary",
        params: {
          id: data,
        },
      });
    },

    // highlightTitle(content, topic) {
    //   const titleWordsRaw = topic.replace(/[^\w\s]/g, "").split(" ");
    //   console.log("1", titleWordsRaw);

    //   const titleWords = titleWordsRaw.filter((word) => word !== "");
    //   console.log("2", titleWords);

    //   const filteredTitleWords = titleWords.filter(
    //     (word) => !stopwords.includes(word)
    //   );
    //   console.log("3", filteredTitleWords);

    //   const pattern = new RegExp(
    //     `\\b(${filteredTitleWords.join("|")})\\b`,
    //     "gi"
    //   );
    //   console.log("4", pattern);

    //   return content.replace(
    //     pattern,
    //     (match) => `<span style="background-color: yellow">${match}</span>`
    //   );
    //   //const regex = new RegExp(this.title, "gi");
    //   //return content.replace(regex, (match) => `<span style="background-color: yellow">${match}</span>`);
    // },

    SummarizeLegis(data) {
      LegislationSearch.SummarizeLegis(data)
        .then((response) => {
          Auth.getMe().then((res) => {
            //deduct credits immediately
            localStorage.setItem("userInfo", JSON.stringify(res.data.data));

            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if (
              !userInfo ||
              userInfo.nexlaw_credits == undefined ||
              userInfo.nexlaw_credits == "undefined" ||
              userInfo.nexlaw_credits == null ||
              userInfo.nexlaw_credits == "null"
            ) {
              localStorage.removeItem("accessToken");
              localStorage.removeItem("userInfo");
              this.$router.push("/login");
            }
          });

          this.$toast.success(response.data.message);
          var arr =
            this.filtered_searched_data[
              this.filtered_searched_data.findIndex((x) => x.id === data)
            ];

          arr.completedPerCentage = response.data.data.completedPerCentage;

          this.filtered_searched_data.splice(
            this.filtered_searched_data.findIndex((x) => x.id === data),
            1,
            arr
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  created() {
    LegislationSearch.GetLegislation(this.$route.params.id)
      .then((response) => {
        this.pageLoading = false;
        this.campaign = response.data.data;

        try {
          this.title = JSON.parse(response.data.data.statement).join(", ");
        } catch (error) {
          this.title = response.data.data.statement;
        }

        response.data.data.legislations.forEach((element) => {

          element.name = element.name.replace(/<b>|<\/b>/g, "");

          this.searched_data.push(element);
          this.filtered_searched_data.push(element);

          // console.log(this.searched_data);

          this.filterCountry = Array.from(
            new Set(this.searched_data.map((obj) => obj["jurisdiction"]))
          );

          // console.log("here1")
          // console.log(this.searched_data)
          // console.log(this.filtered_searched_data)
        });
      })
      .catch((error) => {
        console.log(error);
      });

    var me = this;
    function loop() {
      me.timer_count++;
      me.timeout_timer = setTimeout(() => {
        if (!me.$route.params.id) return;
        
        LegislationSearch.GetLegislation(me.$route.params.id)
          .then((response) => {
            response.data.data.legislations.forEach((element) => {
              me.filtered_searched_data.forEach((element2) => {
                if (element.id == element2.id) {
                  element2.completedPerCentage = element.completedPerCentage;
                }
              });
            });
          })
          .finally(() => {
            if (me.timer_count <= 30) {
              loop();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }, 10000);
    }

    loop();
  },

  destroyed() {
    clearTimeout(this.timeout_timer);
  },

  beforeDestroy() {
    this.timer_count = 0;
    clearTimeout(this.timeout_timer);
  },
};
</script>

<style scoped>
/* table */
.table-card {
  overflow-x: auto;
  width: auto !important;
}

.table {
  border: none;
  background: white;
  table-layout: fixed;
  width: auto !important;
}

tbody {
  display: block;
  max-height: 500px;
  overflow-y: scroll;
}

thead {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border-bottom: 0.1vw solid #ddd !important;
  background: white;
}

tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border: none;
  background: white;
}

td {
  border-bottom: 0.001px solid #ddd;
  border-top: none;
  vertical-align: top;
}

th {
  border: none;
  color: #094890;
}

.container_margin {
  padding: 2rem;
}

.icon-blue {
  font-size: 2em;
  color: #094890;
}

.court_badge {
  border: none;
  background: #d2d2d2;
  color: black;
  border-radius: 20px;
  font-size: 14px;
  padding: 0.3rem 0.5rem;
}

.court_badge .material-icons-outlined {
  font-size: 14px;
  translate: 0.2rem 0.1rem;
}

.select_court {
  margin: 0 auto;
}

.space {
  height: 5%;
}

.juris-modal {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.spinner {
  color: #f08000 !important;
}

.spinner-border {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-vertical-align: -0.15em;
  --bs-spinner-border-width: 0.2em;
}

/* @media only screen and (max-width: 1024px) {
  .btn {
    padding-bottom: 52px;
  }
}

@media only screen and (max-width: 1440px) {
  .btn-primary-outlined {
    padding-bottom: 52px;
  }
} */

.form-control {
  font-size: 14px;
}

#summarizingLoadingButton {
  padding-left: 5px;
  padding-right: 5px;
  width: 180px;
}

.accordion-checkbox {
  display: none;
}

.accordion-label {
  cursor: pointer;
  display: block;
}

.accordion-icon {
  transition: transform 0.3s;
}

.accordion-checkbox:checked ~ .accordion-label .accordion-icon {
  transform: rotate(-180deg);
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
